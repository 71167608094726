<template>
  <div class="warp" style="min-height: 80vh">
    <div class="content">
      <div
        class="item"
        v-for="item in list"
        :key="item.goods_id"
        @click="getNew(item.model)"
      >
        <div class="top">
          <h3>{{ item.goods_name }}</h3>
          <p>{{ item.goods_desc }}</p>
          <el-button size="small" round plain>了解详细</el-button>
        </div>
        <div class="top2" style="text-align: center">
          <img :src="item.imageUrl" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFingerprint } from "../../../api/home/index";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getFingerprint().then((res) => {
        this.list = res.data.code;
        console.log(this.list);
      });
    },
    getNew(model) {
      this.$router.push({ path: model });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  margin: 1.25rem 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* 替代space-between布局方式 */
}
.item {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex: 1;
  /* 间隙为5px */
  margin: 0 2.25rem 2.25rem 0;
  padding: 3.125rem 1.25rem;
  box-sizing: border-box;
  transition: all 0.5s;
  // text-shadow: 3px 3px 3px #8899;

  /* END */
  background-color: #f5f5f5;
  /* 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整 */
  width: calc((100% - 2.25rem) / 2);
  /* END */
  /* 加入这两个后每个item的宽度就生效了 */
  min-width: calc((100% - 2.25rem) / 2);
  max-width: calc((100% - 2.25rem) / 2);
  img {
    min-width: 12.5rem;
    transition: all 1s;
  }

  /* END */
  .top {
    width: 50%;
    margin: 2.25rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    h3 {
      font-size: 1.4375rem;
      color: #11120c;
    }
    p {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #11120c;
      margin: 0.7813vw 0;
    }
    span {
      font-size: 1.125rem;
    }
  }
  .top2{
    width: 50%;
  }

  div[style="text-align: center"] {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 80%;
    min-width: 12.5rem;
    transition: all 1s;
    object-fit: contain;
  }
}
.item:hover {
  // transition: all 0.8s;
  transform: scale(1.03);
  img {
    transform: scale(1.2);
  }
  box-shadow: 0px 6px 9px 4px rgba(0, 0, 0, 0.1);
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 992px) {
  /* Styles for medium devices */
  .item {
    min-width: 100%;
    padding: 0.625rem;
    justify-content: space-around;
    .top {
      width: 50%;
      margin: 0.625rem;
      h3 {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.875rem;
      }
    }
    div[style="text-align: center"] {
      width: 50%;
    }
    img {
      min-width: 8.375rem;
      height: auto;
    }
  }
}

.item:nth-child(2n) {
  /* 去除第3n个的margin-right */
  margin-right: 0;
}
img {
  width: 30%;
  object-fit: cover;
}
</style>
